import React, { useCallback, useEffect } from 'react';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextContent,
  useFlowMachineContextFlowStorage,
  useFlowMachineContextSubscriptionProps,
} from '@lib-appRouter/RouterContext';
import ManagePromoPackageList from './ManagePromoPackageList';
import { DiscountInfo } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';
import { ActionTypes } from '@lib-appRouter//flowSessionStorage';
import Spinner from '../../components/Spinner';
import usePromoCodePackages from '@api/queries/usePromoCodePackages';
import { hasPackageAdded } from '../utils';

const ManagePromoCodePackagesContainer = () => {
  const flowMachine = useFlowMachine();
  const location = useFlowLocation();
  const {
    customerCareCancelPackageNumber,
    vehicleDetails: { make },
    locale,
    tenantId,
  } = useFlowMachineContextSubscriptionProps();
  const { packageSubscriptions } = useFlowMachineContextFlowStorage();
  const { commonWebContent, promoPackagesWebContent, packagesWebContent, trialPackagesWebContent, assets } =
    useFlowMachineContextContent();
  const subscriptionProps = useFlowMachineContextSubscriptionProps();
  const { discountCriteria } = subscriptionProps;

  const { allowMultiplePromoPackagesSelection } = useFlowMachineContext();

  const navigate = () => {
    flowMachine.send({ type: 'NAVIGATE' });
  };

  const discountInfo = () => {
    return { promoCode: discountCriteria.promoCode } as DiscountInfo;
  };

  const addDiscountInfo = useCallback(() => {
    const discount: DiscountInfo = discountInfo();
    flowMachine.send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.AddDiscountInfo, payload: discount },
    });
  }, []);

  const { data: newPreviewPromoCodePackages, isLoading, isFetching } = usePromoCodePackages();

  useEffect(() => {
    if (newPreviewPromoCodePackages?.length) {
      addDiscountInfo();
    }
  }, [addDiscountInfo, newPreviewPromoCodePackages]);
  const onPromoSelection = (promoPackage: EligiblePackageInfo) => {
    const isPackageSelected = hasPackageAdded(packageSubscriptions, promoPackage);
    flowMachine.send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: isPackageSelected ? ActionTypes.RemovePackage : ActionTypes.AddPromoPackage,
        payload: promoPackage,
        allowMultiplePromoPackagesSelection,
      },
    });
  };

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  return (
    <ManagePromoPackageList
      promoPackages={newPreviewPromoCodePackages}
      packageSubscriptions={packageSubscriptions}
      commonWebContent={commonWebContent}
      assets={assets}
      locale={locale}
      tenantId={tenantId}
      make={make}
      promoPackagesWebContent={promoPackagesWebContent}
      trialPackagesWebContent={trialPackagesWebContent}
      packagesWebContent={packagesWebContent}
      customerCareCancelPackageNumber={customerCareCancelPackageNumber}
      location={location}
      canShowBackButton={false}
      send={flowMachine.send}
      navigate={navigate}
      onPromoSelection={onPromoSelection}
    />
  );
};

export default ManagePromoCodePackagesContainer;
