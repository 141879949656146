import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { formatDateByLocale } from '@cv/portal-common-lib';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import useExpireRenewText from '@hooks/useExpireRenewText';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { format } from 'date-fns';
import { getDiscounts, Modal } from '@components-lib';
import { priceTotal } from '@app/components-lib/manageSubscription/utils';
import { EventDataBuilder, EventType } from '@app/components-lib/components/Analytics';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import ServiceItem from './ServiceItem';
import { getSortedServices } from './steps/AllBenefits/allBenefitsUtils';
import { ServiceDescriptions } from './cancellationUtils';
import { AssetObject } from '@utils/assets';
import getFirstExisting from '@utils/getFirstExisting';
import { CancelFlow } from './CancelSubscriptionStateMachine';
import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export type PackageState = 'subscribe' | 'cancel' | 'current';
type Props = {
  pkg: SubscribedPackage | EligiblePackageInfo;
  packageState?: PackageState;
  labels: {
    currencyLabel: string;
    importantCancellationInfo__Annual: string;
    importantCancellationInfo__Monthly: string;
    cancelEffectiveLabel: string;
    expires: string;
    renew: string;
    starts: string;
    details: string;
    servicesHierarchy: string[];
    descriptions: ServiceDescriptions;
    defaultShortDescription: string;
    defaultLongDescription: string;
    cancelCopy_term1: string;
    cancelCopy_term12: string;
    shouldIncludeTax: boolean;
  };
  assets: AssetObject;
  step: CancelFlow;
};

const Price = ({
  price,
  currencyLabel,
  packageState,
}: {
  price: string;
  currencyLabel: string;
  packageState: PackageState;
}) => {
  const havePrefix = /^[-]/.test(price);

  const prefixMap: Record<PackageState, string> = {
    subscribe: '',
    cancel: '-',
    current: '',
  };

  const prefix = havePrefix ? '' : prefixMap?.[packageState] ?? '';

  return (
    <Typography
      textAlign="right"
      sx={(theme) => ({
        color: () => {
          switch (packageState) {
            case 'cancel':
              return theme.palette.error.main;
            default:
              return theme.palette.text.primary;
          }
        },
        whiteSpace: 'nowrap',
      })}
    >
      {currencyLabel}
      {prefix}
      {price}
    </Typography>
  );
};

const PackageLine: React.FC<Props> = (props: Props) => {
  const {
    pkg,
    packageState = 'current',
    labels: {
      currencyLabel,
      cancelEffectiveLabel,
      starts,
      renew,
      expires,
      servicesHierarchy = [],
      descriptions,
      defaultShortDescription,
      defaultLongDescription,
      importantCancellationInfo__Annual = '',
      importantCancellationInfo__Monthly = '',
      details = '',
      shouldIncludeTax = false,
    },
    assets,
  } = props;

  const { marketingName, amountWithoutTax, autoRenew, termEndDate, capableServices, initialTerm } = pkg;

  const reorderedServices = getSortedServices(servicesHierarchy, capableServices);

  const [packageDetailsOpened, setPackageDetailsOpened] = useState(false);

  const defaultDateFormat = 'MM/dd/yyyy';

  const taxTotal = getFirstExisting(pkg, ['taxTotal', 'variant.taxTotal'], 0.0);
  const startDate = getFirstExisting(pkg, ['startDate', 'variant.startDate'], format(Date.now(), defaultDateFormat));
  const endDate = getFirstExisting(pkg, ['endDate', 'variant.endDate'], format(Date.now(), defaultDateFormat));
  const cancelEffectiveDate = getFirstExisting(
    pkg,
    ['cancelEffectiveDate', 'variant.cancelEffectiveDate'],
    format(Date.now(), defaultDateFormat),
  );
  const renewable = getFirstExisting(pkg, ['renewable', 'variant.renewable'], false);
  //Trial will behave like annual product cancellation
  const isTrialPkg =
    'discounts' in pkg ? getDiscounts(pkg.discounts, [OfferType.Trial, OfferType.Trial_renewal]).length > 0 : false;

  const termServiceEndDate = getFirstExisting(
    pkg,
    ['termServiceEndDate', 'variant.termServiceEndDate'],
    format(Date.now(), defaultDateFormat),
  );
  const listPrice = getFirstExisting(pkg, ['listPrice', 'variant.listPrice'], 0.0);

  const formatToFixed = 2;
  const price = amountWithoutTax
    ? priceTotal(amountWithoutTax, shouldIncludeTax && taxTotal)
    : Number(listPrice).toFixed(formatToFixed);

  const expireRenewText = useExpireRenewText();

  const startsCancelLabel =
    packageState === 'subscribe'
      ? `${starts} ${formatDateByLocale(startDate)}`
      : `${cancelEffectiveLabel} ${formatDateByLocale(cancelEffectiveDate)}`;

  const expireRenewRendered = expireRenewText({
    termServiceEndDate,
    autoRenew: !!(autoRenew || renewable),
    termEndDate: termEndDate || endDate,
    renewLabel: renew,
    expiresLabel: expires,
  });

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid xs>
            <Stack>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {marketingName}
              </Typography>
              {startsCancelLabel ? (
                <Typography variant="subtitle2">{startsCancelLabel}</Typography>
              ) : (
                <Typography variant="subtitle2">{expireRenewRendered}</Typography>
              )}
              {packageState === 'cancel' && (
                <Typography variant="subtitle2">
                  {isTrialPkg || initialTerm === 12
                    ? importantCancellationInfo__Annual
                    : importantCancellationInfo__Monthly}
                </Typography>
              )}
              <AnalyticsButton
                variant="text"
                sx={{ cursor: 'pointer', color: 'primary.main', maxWidth: 4, justifyContent: 'flex-start' }}
                onClick={() => setPackageDetailsOpened(true)}
                analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
                  text: details,
                  pageName: props.step,
                })}
              >
                {details}
              </AnalyticsButton>
            </Stack>
          </Grid>
          <Grid xs={3} md={2}>
            <Price price={price} currencyLabel={currencyLabel} packageState={packageState} />
          </Grid>
        </Grid>
      </Box>

      <Modal
        title={marketingName}
        open={packageDetailsOpened}
        showXButton
        onClose={() => setPackageDetailsOpened(false)}
      >
        <Stack spacing={2}>
          {reorderedServices?.map((service) => (
            <ServiceItem
              service={service}
              assets={assets}
              descriptions={descriptions}
              defaultShortDescription={defaultShortDescription}
              defaultLongDescription={defaultLongDescription}
              key={service.vehicleServiceId}
            />
          ))}
        </Stack>
      </Modal>
    </>
  );
};

export default PackageLine;
