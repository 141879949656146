import { useCallback } from 'react';
import { ServicesContentItem } from '@customTypes/ContentfulComponent';
import useVehicle from '@api/queries/useVehicle';

export const checkRenderServices = (contentfulItem: ServicesContentItem, vehicleServices: string[]) =>
  !contentfulItem.services ||
  contentfulItem.services.every((supportedService) => vehicleServices.includes(supportedService));

const useFilterByServices = () => {
  const { data: vehicle } = useVehicle();
  return useCallback(
    (contentItems: ServicesContentItem[]) =>
      contentItems.filter((item) => checkRenderServices(item, vehicle?.services || [])),
    [vehicle],
  );
};

export default useFilterByServices;
