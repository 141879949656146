import { FormControl, FormControlLabel } from '@lib-components/FormControl';
import { Radio, RadioGroup } from '@lib-components/Radio';
import { WebContent } from '@manageSubscription/Subscription';
import { ApplePayPayment, NewPayment, PayPalPayment } from '@lib-payment/components/Payment/paymentMethods';
import { PAYMENT_LABEL_IMAGE_SIZE, PaymentOptions } from '@lib-payment/Types';
import React from 'react';
import { CenterAlignedContent } from '../../styles';
import SectionHeader from '../SectionHeader';
import Image from '@lib-components/Image';
import { Grid } from '@lib-components/Grid';
import { usePaymentContext } from '@lib-payment/components/Payment/PaymentContext';
import { Can, useFeatureFlags } from '@components/FeatureFlags';

export interface PaymentMethodsPops {
  cancelEditing?: () => void;
}

interface PaymentMethodsLocalPops extends PaymentMethodsPops {
  content: WebContent;
  wayToPay: PaymentOptions;
  setWayToPay: (method: PaymentOptions) => void;
  payPalClientId?: string;
}

const PaymentMethods: React.FC<PaymentMethodsLocalPops> = ({
  cancelEditing,
  wayToPay,
  setWayToPay,
  content,
  payPalClientId,
}) => {
  const { assets, newPaymentInfo } = content;
  const { enableApplePay, enablePayPal } = useFeatureFlags();

  const {
    subscriptionProps: { supportedCreditCards },
  } = usePaymentContext();

  const renderWayToPay = () => {
    switch (wayToPay) {
      case PaymentOptions.CREDIT_CARD:
        return <NewPayment cancelEdit={cancelEditing} />;
      case PaymentOptions.PAYPAL:
        return <PayPalPayment cancelEdit={cancelEditing} />;
      case PaymentOptions.APPLE_PAY:
        return <ApplePayPayment cancelEdit={cancelEditing} />;
      default:
        return null;
    }
  };

  return (
    <CenterAlignedContent flexDirection="column">
      <SectionHeader>{newPaymentInfo}</SectionHeader>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup value={wayToPay} onChange={(_, value) => setWayToPay(value as PaymentOptions)}>
          <Grid container spacing={2}>
            <Grid item flexGrow={1} flexBasis="100%">
              <FormControlLabel
                value={PaymentOptions.CREDIT_CARD}
                control={<Radio />}
                label={
                  <CenterAlignedContent>
                    {supportedCreditCards.map((cardType) => (
                      <Image inline assets={assets} name={cardType} key={cardType} size={PAYMENT_LABEL_IMAGE_SIZE} />
                    ))}
                  </CenterAlignedContent>
                }
              />
            </Grid>
            {enableApplePay && 'ApplePaySession' in window && (
              <Grid item>
                <FormControlLabel
                  value={PaymentOptions.APPLE_PAY}
                  control={<Radio />}
                  label={<Image inline assets={assets} name="ApplePay" size={PAYMENT_LABEL_IMAGE_SIZE} />}
                />
              </Grid>
            )}
            {payPalClientId && enablePayPal && (
              <Grid item>
                <FormControlLabel
                  value={PaymentOptions.PAYPAL}
                  control={<Radio />}
                  label={<Image inline assets={assets} name="PayPal" size={PAYMENT_LABEL_IMAGE_SIZE} />}
                />
              </Grid>
            )}
          </Grid>
        </RadioGroup>
      </FormControl>
      {renderWayToPay()}
    </CenterAlignedContent>
  );
};

export default PaymentMethods;
