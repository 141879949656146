import React, { useState } from 'react';
import useVehicle from '@api/queries/useVehicle';
import Modal from '@lib-components/NewModal/Modal';
import { Button } from '@app/components-lib';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Can, FeatureFlagsKeys } from '@components/FeatureFlags';

interface Labels {
  serviceRequiredText: string;
  serviceRequiredHeader: string;
  serviceRequireButtonText: string;
}

interface Props {
  children: React.ReactNode;
  labels: Labels;
}
const BillingsystemBlocker = ({ children, labels = {} }: Props) => {
  const { data: vehicle } = useVehicle();
  const { serviceRequiredText, serviceRequiredHeader, serviceRequireButtonText } = labels;
  const [open, setOpen] = useState(!!vehicle?.billingSystem);

  return (
    <>
      <Can featureFlag={FeatureFlagsKeys.blockVehicleByBillingSystem}>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          title={serviceRequiredHeader}
        >
          <Stack gap={2} alignContent="center">
            <Typography>{serviceRequiredText}</Typography>
            <Button component={Link} to="/login">
              {serviceRequireButtonText}
            </Button>
          </Stack>
        </Modal>
      </Can>
      {children}
    </>
  );
};

export default BillingsystemBlocker;
