import React, { createContext, ReactNode, useContext } from 'react';

export enum FeatureFlagsKeys {
  displayAlaCartePackagesInFlow = 'displayAlaCartePackagesInFlow',
  newFeature = 'newFeature',
  salesforceChat = 'salesforceChat',
  shouldIncludeTax = 'shouldIncludeTax',
  passwordEncryption = 'passwordEncryption',
  vinLookupShowGoToEproof = 'vinLookupShowGoToEproof',
  showLogoutButton = 'showLogoutButton',
  enableApplePay = 'enableApplePay',
  enablePayPal = 'enablePayPal',
  blockVehicleByBillingSystem = 'blockVehicleByBillingSystem',
}

export type FeatureFlag = {
  componentType: 'portalFeatureFlag';
  enabled: boolean;
  key: FeatureFlagsKeys;
  name: string;
};

export type FeatureFlagsContextType = Partial<Record<FeatureFlagsKeys, boolean>>;
const FeatureFlagsContext = createContext<FeatureFlagsContextType>({});

type FeatureFlagsProviderProps = {
  children: ReactNode;
  featureFlags: FeatureFlag[];
};

export const FeatureFlagsProvider = ({ children, featureFlags }: FeatureFlagsProviderProps) => {
  const flags = featureFlags.reduce<FeatureFlagsContextType>((acc: FeatureFlagsContextType, flag) => {
    acc[flag.key] = flag.enabled;
    return acc;
  }, {});
  const flagsProxy = new Proxy(flags, {
    get: function (target, prop: FeatureFlagsKeys) {
      if (prop in target) {
        return target[prop];
      }
      return false;
    },
  });
  return <FeatureFlagsContext.Provider value={flagsProxy}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);

  return context;
};

type CanProps = {
  children: ReactNode;
  featureFlag?: FeatureFlagsKeys;
};

export const Can = ({ children, featureFlag }: CanProps) => {
  const flags = useFeatureFlags() as FeatureFlagsContextType;

  const isEnabled = flags[featureFlag as FeatureFlagsKeys];
  if (isEnabled) {
    return <>{children}</>;
  }
  return null;
};
