import { formatDateByLocale } from '@cv/portal-common-lib';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { alpha, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';

type Props = {
  currencyLabel: string;
  children?: React.ReactNode;
  preview: PreviewOrderResponse;
  labels: {
    subscriptionSummary: string;
    subTotal: string;
    refund: string;
    refundTaxLabel: string;
    totalTax: string;
    refundDueLabel: string;
    totalAmount: string;
    paymentDate: string;
  };
};

const PreviewSummary = (props: Props) => {
  const { currencyLabel, children, preview, labels } = props;
  const {
    subscribedPackages = [],
    amountTotal = 0,
    refundAmountTotal = 0,
    amountWithoutTaxTotal = 0,
    taxTotal = 0,
    refundTaxTotal = 0,
    refundAmountWithoutTaxTotal = 0,
  } = preview;

  const { subscriptionSummary, subTotal, refund, refundTaxLabel, totalTax, refundDueLabel, totalAmount, paymentDate } =
    labels;
  const hasRefundTaxAmounts = Boolean(Number(refundAmountWithoutTaxTotal ?? 0) || Number(refundTaxTotal ?? 0));
  const hasRefundAmount = Boolean(Number(refundAmountTotal ?? 0));

  let startDate = '';

  try {
    startDate = formatDateByLocale(subscribedPackages[0]?.startDate);
  } catch (error) {}

  return (
    <Grid
      container
      mb={3}
      sx={(theme) => ({
        maxWidth: '90vw',
        alignSelf: 'center',
        padding: theme.spacing(2),
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      })}
    >
      <Grid xs={12}>
        <h3>
          <b>{subscriptionSummary}</b>
        </h3>
      </Grid>

      <Grid xs={12}>
        <Divider sx={{ mt: 2, mb: 2 }} />
      </Grid>

      <Grid xs={6}>
        <Typography variant="body1">{subTotal}</Typography>
      </Grid>
      <Grid xs={6}>
        <Typography variant="subtitle1" textAlign="right">
          {currencyLabel}
          {amountWithoutTaxTotal}
        </Typography>
      </Grid>

      {hasRefundTaxAmounts && (
        <>
          <Grid xs={6}>
            <Typography variant="body1">{refund}</Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="body1" textAlign="right">
              {currencyLabel}
              {refundAmountWithoutTaxTotal}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="body1">{refundTaxLabel}</Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="body1" textAlign="right">
              {currencyLabel}
              {refundTaxTotal}
            </Typography>
          </Grid>
        </>
      )}

      <Grid xs={6}>
        <Typography variant="body1">{totalTax}</Typography>
      </Grid>
      <Grid xs={6}>
        <Typography variant="body1" textAlign="right">
          {currencyLabel}
          {taxTotal}
        </Typography>
      </Grid>

      {hasRefundAmount && (
        <>
          <Grid xs={6}>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {refundDueLabel}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1" textAlign="right">
              {currencyLabel}
              {refundAmountTotal}
            </Typography>
          </Grid>
        </>
      )}

      <Grid xs={6}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
          {totalAmount}
        </Typography>
      </Grid>
      <Grid xs={6}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6" textAlign="right">
          {currencyLabel}
          {amountTotal}
        </Typography>
      </Grid>

      <Grid xs={12}>
        <Divider sx={{ mt: 2, mb: 2 }} />
      </Grid>

      {startDate && (
        <>
          <Grid xs={6}>
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {paymentDate}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography sx={{ fontWeight: 'bold' }} variant="h6" textAlign="right">
              {startDate}
            </Typography>
          </Grid>
        </>
      )}

      {children}
    </Grid>
  );
};

export default PreviewSummary;
